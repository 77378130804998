import axios from 'axios';

let backendBankUrl = process.env.REACT_APP_BANK_URL;
if (backendBankUrl == null) {
    backendBankUrl = window.ERP_OA.REACT_APP_BANK_URL;
}
const URL = backendBankUrl;

export const getBankNameFromIban = async (iban) => {
    const response = await axios.get(URL, {params: {iban: iban}});
    if (response.hasOwnProperty('data') && Array.isArray(response.data)) {
        return response.data[0];
    }

    return null;
};

export const trimIban = (getValues, setValue)=> {
    const iban = getValues()['bankAccount.iban'];
    const ibanTrimmed = iban.replace(/\s/g, '');
    if (iban !== ibanTrimmed){
        setValue('bankAccount.iban', ibanTrimmed, true);
    }
}

export const setBankName = async (getValues, setValue) => {
    const iban = getValues()['bankAccount.iban'];
    const ibanTrimmed = iban.replace(/\s/g, '');
    try {
        const bankName = await getBankNameFromIban(ibanTrimmed);
        setValue('bankAccount.bankName', bankName, true);
    } catch (error) {
        console.log(error.message())
    }
};
